'use strict';
/**
 * The job list
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.machines')
    .controller('machinesController', function($scope, $state, DataManager, Notification) {

  $scope.machines = [];

  $scope.tableButtons = [{
    'icon':'plus',
    'title':'Ajouter',
    'function': () => {
      $state.go('machines/machine-creation');
    }
  }];

  $scope.tableStructure = [
    {
      'title':'Number',
      'value':'number',
      'filter':'alpha'
    },
    {
      'title':'Type',
      'value':'type',
      'filter':'alpha'
    }
  ];

  /**
   * Initialisation function
   */
  $scope.init = function() {
    DataManager.fetchMachines().then(data => {
      $scope.machines = data;
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un probème est survenu lors du chargement des jobs');
    });
  };

  /**
   * Link to go to the machine edition page
   * @param {Object} machine - the machine to edit
   */
  $scope.selectMachine = function(machine) {
    $state.go('machines/machine-creation', {'machineId': machine.id});
  };

  $scope.init();

});
